body {
  font-family: 'DM Serif Display', serif;
  --dimmer-color: #c48b9f;
  --highlight-color: #ffeeff;
  --highlight-color-rgb: 255, 238, 255;
  --border-color: white;
  --number-columns: 3;
  --side-padding: 24px;
  --border-img-width: 8px;
}

.mattgoo {
  background-color: #f8bbd0;
}

.container {
  display: grid;
  grid-template-columns: var(--side-padding) repeat(var(--number-columns), 1fr) var(--side-padding);
  padding-top: var(--side-padding);
}

.container__header {
  padding-left: var(--side-padding);
  display: flex;
  align-items: center;
  max-height: 80px;

  border-bottom: 1px solid var(--border-color);
  background: var(--highlight-color);
  box-shadow: 0 5px 8px #0000001c;
}

.container__header img {
  max-height: 80px;
}

.container__header h1 {
  margin: 0;
  margin-left: 16px;
}

.container__item {
  grid-column-start: 2;
  grid-column-end: calc(var(--number-columns) + 1);
}

.container__text {
  font-size: 1.5rem;
}

.container__item--alt {
  justify-self: end;
  grid-column-start: var(--number-columns);
  grid-column-end: calc(var(--number-columns) + 2);
  text-align: right;
}

.container__img {
  max-height: 80vh;
  background: var(--border-color);
  padding: var(--border-img-width);
  box-sizing: border-box;
}

.container--space {
  margin-bottom: 10vh;
}
  
.text-highlight {
  color: var(--dimmer-color);
  color: var(--dimmer-color);
}

@media only screen and (max-width: 600px) {
  .container__item,
  .container__item--alt,
  .container__header {
    grid-column-start: 2;
    grid-column-end: calc(var(--number-columns) + 2);
    justify-self: auto;
  }

  .container__img {
    width: 100%;
  }

  .container__text {
    text-align: right;
  }
}